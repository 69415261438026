/**
 * Google Font loader
 * @type {Object}
 */
window.WebFontConfig = {
  google: { families:
    [
      'Montserrat:400,500,600,700',
    ],
   },
};
(function() {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();

function menuToggle() {
    jQuery('#navbar').on('show.bs.collapse', function () {
        jQuery('html').addClass('overflow-hidden');
    });
    jQuery('#navbar').on('hide.bs.collapse', function () {
        jQuery('html').removeClass('overflow-hidden');
    });
}

function basket_lg() {
    if ($('#basket__count__lg').length === 0) {
        var basketHTML = '<div class="basket__icon"><span class="sr-only">Basket items: </span><div id="basket__count__lg" class="basket__count">0</div></div>';

        $('li#menu-item-1230').find('a').html(basketHTML);
    }
}

// function custom_selects() {
//     $('.woocommerce-ordering').find('select').each(function(){
//         $(this).wrap('<div class="select-wrap"></div>');
//     });
// }

function slick_slides() {

    $('.video-player-js').slick({
     slidesToShow: 1,
     slidesToScroll: 1,
     arrows: true,
     dots: false,
     fade: true,
     asNavFor: '.video-player-nav-js'
    });

    $('.video-player-nav-js').slick({
     slidesToShow: 3,
     slidesToScroll: 1,
     asNavFor: '.video-player-js',
     arrows: true,
     dots: false,
     prevArrow: '.video-player-nav-buttons__left',
     nextArrow: '.video-player-nav-buttons__right',
     variableWidth: false,
     centerPadding: '0px',
     centerMode: true,
     focusOnSelect: true,
     responsive: [
       {
         breakpoint: 788,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1
         }
       },
       {
         breakpoint: 520,
         settings: {
           // centerPadding: '0px',
           // centerMode: true,
           // variableWidth: true,
           // slidesToShow: 1,
           // slidesToScroll: 1,

           slidesToShow: 1,
           slidesToScroll: 1,
           centerMode: false,
         }
       }
     ]
    }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $(".video-js").each(function (index) {
        this.player.pause();
      });
    });

    if ( $('.steps-js').length > 0 ) {

        $('.steps-js').slick({
          dots: true,
          speed: 500,
          arrows: false,
          autoplay: false,
          pauseOnFocus: false,
          fade: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          variableWidth: false,
          infinite: false,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

    }

    if ( $('.product-thumbnails-js').length > 0 ) {

        $('.product-thumbnails-js').slick({
          dots: false,
          speed: 500,
          arrows: true,
          autoplay: false,
          pauseOnFocus: false,
          fade: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          variableWidth: false,
          infinite: true,
          prevArrow: '.product-thumbnails-buttons__left',
          nextArrow: '.product-thumbnails-buttons__right',
          responsive: [
            {
              breakpoint: 528,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
              }
            },
          ]
        });

    }

    if ($('.js-products-carousel').length > 0) {

        $('.js-products-carousel').slick({
            dots: true,
            speed: 500,
            arrows: false,
            autoplay: false,
            pauseOnFocus: false,
            fade: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            variableWidth: true,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

    }


    if ($('.js-stockists').length > 0) {

        $('.js-stockists').slick({
            dots: false,
            speed: 500,
            arrows: true,
            autoplay: false,
            pauseOnFocus: false,
            fade: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            prevArrow: '.slider-buttons__left',
            nextArrow: '.slider-buttons__right'
        });

    }

    if ($('.js-blog-carousel').length > 0) {

        $('.js-blog-carousel').slick({
            dots: false,
            speed: 500,
            autoplay: false,
            pauseOnFocus: false,
            fade: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            arrows: true,
            prevArrow: '.slider-buttons__left',
            nextArrow: '.slider-buttons__right',
        });

    }

    if ($('.js-videos-carousel').length > 0) {
        $('.js-videos-carousel').slick({
            dots: false,
            speed: 500,
            arrows: true,
            autoplay: false,
            pauseOnFocus: true,
            fade: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            // variableWidth: true,
            centerMode: true,
            prevArrow: '.slider-buttons__left',
            nextArrow: '.slider-buttons__right',
            responsive: [
                {
                    breakpoint: 1250,
                    settings: {
                        centerMode: true,
                        variableWidth: true,
                    }
                },
                {
                    breakpoint: 870,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerMode: true,
                        variableWidth: true,
                    }
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        variableWidth: true,
                    }
                },
                {
                    breakpoint: 410,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    }
                }
            ]
        });

        if(window.location.hash) {
          var $id = $(window.location.hash);
          var attr = $id.attr('data-lity');

          if (typeof attr !== typeof undefined && attr !== false) {
            $id.trigger('click');
            console.log('test');
          }
        }
    }
}

function woocommerce_layout_custom() {
    if ( $('.woocommerce-generated').length > 0 )   {
        var el = $('.woocommerce-generated');
        var elTarget = $('.product-heading__basket');

        // Move basket to product header section
        elTarget.append(el.find('form.cart'));
        el.find('.price').remove();
        el.find('.product_meta').remove();
        el.find('div[itemprop="description"]').remove();
        el.find('.woocommerce-tabs').remove();
    }

}

function selectMenus() {

    if ( $('.input-group__js').length > 0 ) {
        $('.input-group__js').each(function(){
            $(this).find('select').wrap('<div class="select-wrap"></div>');
        });
    }
}

(function ($) {

    menuToggle();
    basket_lg();
    woocommerce_layout_custom();
    slick_slides();
    selectMenus();

    $(document).ready(function (e) {

        $('#trade-form, #profile-form, #subscription-form').submit(function () {
            var $form = $(this);
            var $inputs = $form.find("input, select, textarea");

            $inputs.removeClass('error');
            $inputs.each(function () {
                if ($(this).parent().find('label span').hasClass('required') && $(this).val().length === 0) {
                    $(this).addClass('error');
                }

            });

            if ($form.find('.error').length > 0) {
                var $errorArea = $(".woocommerce");

                // Remove element to avoid notice repetition
                $('.woocommerce-error').remove();
                $errorArea.prepend('<ul class="woocommerce-error"></ul>');

                $(".error").each(function () {
                    var $error = $(this).parent().find('label')[0].childNodes[0].nodeValue;
                    var $errorVal = $error.replace(':', '');

                    $(".woocommerce-error").append("<li>" + $errorVal + " is required</li>");
                    window.scrollTo(0, 0);
                });

                return false;
            }
        });

    });

    var intervalMenu;

    function stopCallBack() {
        clearInterval(intervalMenu);
    }

    function startInterval() {
        intervalMenu = window.setInterval(menuCallback, 500);
    }

    function menuCallback() {
        if ($('#menu-product-menu:visible').length > 0) { stopCallBack(); $('body').addClass('show-menu'); startInterval(); } else { stopCallBack(); $('body').removeClass('show-menu'); startInterval(); }
    }




    if ($('.fl-menu-mobile-toggle').length > 0) {
        $('.fl-menu-responsive-toggle-mobile').prepend('<button title="Close (Esc)" type="button" class="fl-menu-mobile-toggle hamburger-override">×</button>');
        $('.fl-menu-responsive-toggle-mobile').append('<div class="overlay"></div>');
        startInterval();
    }

    $(document).on('click', '.hamburger-override', function (e) {
        e.preventDefault();

        if ($('#menu-product-menu:visible').length > 0) {
            $('#menu-product-menu:visible').hide();
            $('#menu-product-menu:visible').removeAttr('style');
            $('body').removeClass('show-menu');
        } else {
            $('#menu-product-menu').show();
            $('body').addClass('show-menu');
        }
    });

    setTimeout(function () {
        if ($('.woocommerce-product-gallery').find('.flex-control-nav').length > 0) {
            $('.woocommerce-product-gallery').find('.flex-control-nav').slick({
                dots: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 4,
                arrows: true,
                autoplay: false,
                autoplaySpeed: 2000,
                pauseOnFocus: false,
                fade: false
            });
        }
    }, 500);

    if ($('.widget_layered_nav_filters').find('ul').length > 0) {
        $('.fl-node-5a32b1127fbe9').addClass('fl-node-visible');
    }

    var submenuCategories = $('.product-categories');

    if (submenuCategories.length > 0) {

        setTimeout(function () {

            submenuCategories.find('.cat-parent').each(function () {
                $(this).append('<span class="fl-menu-toggle"></span>');
            });

            submenuCategories.find('li').not(".current-cat-parent, .current-cat").find('.children').each(function () {
                $(this).addClass('children--hidden');
            });

            submenuCategories.find('.current-cat-parent').addClass('active-list');
            submenuCategories.find('.current-cat-parent').find('.fl-menu-toggle').addClass('.fl-menu-toggle--active');

        }, 500);

        submenuCategories.on('click', '.fl-menu-toggle', function (e) {
            e.preventDefault();
            $(this).toggleClass('fl-menu-toggle--active');
            $(this).parent().toggleClass('active-list');
            $(this).parent().find('> .children').toggleClass('children--hidden');
        });
    }

})(jQuery); // Fully reference jQuery after this point.
